'use client';

import { useTranslation } from '../i18n/client';
import Image from 'next/image';
import { useParams, useRouter } from 'next/navigation';

import NotFoundImage from '@/assets/images/not-found-image.png';
import BaseButton from '@/components/base/BaseButton/BaseButton';

export default function NotFound() {
  const { lang } = useParams();
  const { t } = useTranslation(lang, 'common');
  const router = useRouter();

  const handleBackToHome = () => {
    router.push(`/${lang}`);
  };

  return (
    <div className="flex h-full w-full flex-col items-center bg-primary-50 p-5 md:flex-row md:justify-center md:gap-5 md:p-20">
      <div className="flex flex-col gap-4 md:w-1/2 md:gap-10">
        <div className="flex flex-col gap-2 text-gray-900">
          <h1 className="text-3xl font-semibold lg:text-[64px] lg:font-bold lg:leading-[80px]">
            {t('utils.error.pageNotFound')}
          </h1>
          <p className="text-sm lg:text-lg">
            {t('utils.error.pageNotFoundDescription')}
          </p>
        </div>
        <div className="flex items-center justify-end md:justify-start">
          <BaseButton variant="primary" onClick={handleBackToHome} size="l">
            <p className="typo-paragraph-sm text-white">
              {t('utils.error.backToHome')}
            </p>
          </BaseButton>
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:max-w-[520px]">
        <Image src={NotFoundImage} alt="not found image" className="w-full" />
      </div>
    </div>
  );
}
